import React, { useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react'

export function WithPromise(props) {
    const { promise } = props
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true)
        promise.then((resp) => {
            setData(resp);
            setLoading(false)
        })
    }, [promise])


    return (loading) ? <Loader active={true} /> : props.children(data);
}
