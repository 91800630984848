import React from 'react';
import { Icon } from 'semantic-ui-react'
import styles from './Stat.module.css';

export function Stat(props) {
    return (
        <div className={styles.stat}>
            <div className={styles.statName}>
                <Icon name={props.icon} />
                <span className={styles.statNameMajor}>{props.major}</span>
                <span className={styles.statNameMinor}>{props.minor || ""}</span>
            </div>
            <div className={styles.statValue}>
                {props.value}<span className={styles.statUnit}>{props.unit}</span>
            </div>
        </div>
    )
}
