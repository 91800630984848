import React from 'react';
import { Projects } from './Projects'
import { Resources } from './Resources'
import { Dashboard } from './Dashboard'
import { User } from './User'
import { Project } from './Project'
import { AppHeader } from '../components/AppHeader'


import styles from './App.module.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import moment from 'moment'

moment.locale('de');

export function App(props) {
    let { week, year } = currentDate();
    return (
        <Router>
            <div className={styles.App}>
                <AppHeader />
                <Switch>
                    <Route path="/projects/:project/" component={Project} />
                    <Route path="/resources/users/:user/" component={User} />

                    <Route path="/dashboard/" component={Dashboard} />
                    <Route path="/projects/" component={Projects} />
                    <Route path="/resources/" component={Resources} />
                    <Redirect to={"/dashboard/" + year + "/" + week} />
                </Switch>
            </div>
        </Router>
    );
}

function currentDate() {
    let today = moment()
    let week = today.isoWeek();
    let year = today.format('YYYY')
    return {
        year, week
    }
}
