export function getPercentageClass(p) {
    if (p === 'n/a') {
        return 'card-grey';
    }
    if (p >= 75) {
        return 'card-green';
    }
    if (p >= 60) {
        return 'card-orange';
    }
    return 'card-red';
}
