import React from 'react';
import { Stat } from './Stat'
import { formatSeconds } from "../functions/formatSeconds";
import { getPercentageClass } from "../functions/getPercentageClass";
import { Segment } from 'semantic-ui-react'
import { Stats } from './Stats'
import './UserInfo.css';

export function UserInfo(props) {
    const { seconds_spent, seconds_available } = props.stats
    const spent = formatSeconds(seconds_spent)
    const avail = formatSeconds(seconds_available)
    const percentage = (seconds_available > 0) ? Math.round((seconds_spent / seconds_available) * 100) : "n/a";
    const percentageUnit = (seconds_available > 0) ? '%' : "";
    const className = getPercentageClass(percentage);
    return (
        <Segment className={className}>
            <Stats>
                <Stat major="Gebucht" minor="in KW" value={spent.time} unit={spent.unit} icon="book" />
                <Stat major="Verfügbar" minor="in KW" value={avail.time} unit={avail.unit} icon="calendar outline" />
                <Stat major="Auslastung" minor="in KW" value={percentage} unit={percentageUnit} icon="percent" />
            </Stats>
        </Segment>
    )
}
