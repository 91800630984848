import {
    isArray,
    join,
    filter,
    isEmpty
} from 'lodash'

function asArray(args) {
    const arr = []
    for (var i = 0; i < args.length; i++) {
        arr.push(args[i])
    }
    return arr;
}

export function classNames(classes) {
    const classesArray = (isArray(classes)) ? classes : asArray(arguments)
    return {
        className: join(filter(classesArray, (x) => !isEmpty(x)), ' ')
    }
}
