import React from 'react';
import { Header } from 'semantic-ui-react'
import styles from './DashboardElem.module.css';

export function DashboardElem(props) {
    let style = props.centered ? { textAlign: 'center' } : {}
    return (
        <div>
            <Header as="h4" style={style} className={styles.title}>{props.overline ? <div className={styles.overline}>{props.overline}</div> : null}{props.title}</Header>
            {props.children}
        </div>
    )
}
