export function getWorkdays(start, end) {
    start = start.clone().startOf('day');
    end = end.clone().startOf('day');
    let weekdays = []
    while (start <= end) {
        if (start.format('E') !== '6' && start.format('E') !== '7') {
            weekdays.push(start.clone())
        }
        start.add(1, 'days');
    }
    return weekdays;
}

export function getWeekdays(start, end) {
    start = start.clone().startOf('day');
    end = end.clone().startOf('day');
    let weekdays = []
    while (start <= end) {
        weekdays.push(start.clone())
        start.add(1, 'days');
    }
    return weekdays;
}