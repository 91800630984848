import { groupBy, sortBy, sumBy } from 'lodash'

export function createTreeFromWorklogs(worklogs, level1, level2) {
    // First group the logs by customer
    const worklogsByLevel1 = Object.values(groupBy(worklogs, worklog => worklog[level1].id))
    // Create a customer node for each group
    const result = sortBy(worklogsByLevel1.map(worklogs => {
        // All customers will be the same, so select the first one
        const level1Value = worklogs[0][level1]
        // Group the customer worklogs by project
        const worklogsByLevel2 = Object.values(groupBy(worklogs, worklog => worklog[level2].id))
        // Create a project node for each group
        const level2Values = sortBy(worklogsByLevel2.map(worklogs => {
            // All projects will be the same, so select the first one
            const level2Value = worklogs[0][level2]
            // Return the node
            return {
                ...level2Value,
                worklogs: worklogs,
            }
        }), 'name')
        // Return the node
        return {
            ...level1Value,
            worklogs: worklogs,
            children: level2Values
        }
    }), 'name')
    return result;
}
