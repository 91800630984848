export function formatSeconds(s) {
    const hours = s / (60 * 60);
    if (hours <= 8) {
        return {
            time: Math.round(hours * 100) / 100,
            unit: 'h'
        }
    }
    const days = hours / 8
    return {
        time: Math.round(days * 100) / 100,
        unit: 'pt'
    }
}
