import React from 'react';
import { getTimes } from '../api.js';
import { page } from '../functions/page'
import { getWorkdays } from '../functions/getWorkdays'
import { createTreeFromWorklogs } from '../functions/createTreeFromWorklogs'
import { UserInfo } from '../components/UserInfo'
import { WithTimeRange } from '../components/WithTimeRange'
import { WithPromise } from '../components/WithPromise'
import { TreeTable, TreeTableExpand } from '../components/TreeTable'
import { sumBy, fromPairs } from 'lodash'
import styles from './Resources.module.css'
import { Header } from 'semantic-ui-react'
import { NavLink } from "react-router-dom";

function getData(start, stop) {
    return getTimes({
        start: start.format('YYYY-MM-DD'),
        stop: stop.format('YYYY-MM-DD'),
        expand: 'department,task,user',
    })
}

function nameRenderer(row, column, level, config) {
    const heading = (level === 0) ? "h3" : "h4"
    const dashboardLink = (level === 1) ? (
        <NavLink to={`/resources/users/${row.id}`}>Benutzer-Dashboard</NavLink>
    ) : null;
    return (
        <TreeTableExpand row={row} config={config} level={level}>
            <Header as={heading} >
                <Header.Content>
                    {row[column.key]}
                    <Header.Subheader>
                        <small>{dashboardLink}</small>
                    </Header.Subheader>
                </Header.Content>
            </Header>
        </TreeTableExpand>
    )
}

function dataRenderer(range) {
    const startDate = parseInt(range.start.format("YYYYMMDD"))
    const endDate = parseInt(range.end.format("YYYYMMDD"))
    const workDays = getWorkdays(range.start, range.end).length
    return (row, column, level, config) => {
        const workHours = (row.week_hours / 5.0) * 60 * 60
        const stats = {
            seconds_spent: sumBy(row.worklogs.filter(log => parseInt(log.day) >= startDate && parseInt(log.day) <= endDate), log => parseInt(log.seconds_spent)),
            seconds_available: workHours * workDays
        }
        return (
            <UserInfo stats={stats} />
        )
    }
}

function topStat({ label, value }) {
    return (
        <div className={styles.topStat}>
            <p className={styles.topStatLabel}>
                {label}
            </p>
            <p className={styles.topStatValue}>
                {value}
            </p>
        </div>
    )
}

export function Resources(props) {
    return page("resources", props, me =>
        <WithTimeRange>{({ selected, previous }) => {
            const start = previous.start
            const stop = selected.end
            return (
                <WithPromise promise={getData(start, stop)}>{(worklogs) => {
                    const tree = createTreeFromWorklogs(worklogs, 'department', 'user')
                    const usersById = fromPairs(worklogs.map(log => [log.user_id, log.user]))
                    const users = Object.values(usersById).filter(user => user.id != 1) // ID 1 = Unbekannter Benutzer als Fallback
                    const headcount = users.length
                    const ftes = (((sumBy(users, 'week_hours')) / 40.0)).toFixed(2)
                    const capa = ((sumBy(users, user => user.week_hours * (user.availability_percent / 100))) / 40.0).toFixed(2)
                    return (
                        <div>
                            <div className={styles.topStats}>
                                {topStat({ label: 'HC', value: headcount })}
                                {topStat({ label: 'FTE', value: ftes })}
                                {topStat({ label: 'Kapa', value: capa })}
                            </div>
                            <TreeTable
                                columns={[
                                    {
                                        key: "name",
                                        title: "",
                                        renderData: nameRenderer
                                    },
                                    {
                                        key: "2",
                                        title: previous.title,
                                        renderData: dataRenderer(previous)
                                    },
                                    {
                                        key: "3",
                                        title: selected.title,
                                        highlight: true,
                                        renderData: dataRenderer(selected)
                                    }
                                ]}
                                data={tree}
                            />
                        </div>
                    )
                }}
                </WithPromise>
            )
        }}
        </WithTimeRange>
    )
}
