import { ReplaySubject } from 'rxjs'
import axios from 'axios';

const client = axios.create({
    baseURL: '/api/v1',
    headers: {}
});

function getData(response) {
    if (response && response.data) {
        return response.data
    }
    return response
}

const UserSubject = new ReplaySubject(1)

export const CurrentUser = Object.freeze({

    get() {
        client.get('/me').then(getData).then(user => {
            UserSubject.next(user)
        }, error => {
            UserSubject.error(error)
        })
        return UserSubject
    }

})
