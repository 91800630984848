import React from 'react';
import { Stat } from './Stat'
import { getWorkdays } from "../functions/getWorkdays";
import { formatSeconds } from "../functions/formatSeconds";
import { getPercentageClass } from "../functions/getPercentageClass";
import { Segment } from 'semantic-ui-react'
import { Stats } from  './Stats'
import { sumBy } from 'lodash'

import './UserInfo.css';

export function UserStats({data, user}) {
    const weekdays = getWorkdays(data.start, data.stop)
    const workSecondsPerDay = (user.week_hours / 5) * 60 * 60
    const secondsAvailable = weekdays.length * workSecondsPerDay
    const secondsSpent = sumBy(data.worklogs, 'seconds_spent')
    const percentage = (secondsAvailable > 0) ? Math.round((secondsSpent / secondsAvailable) * 100) : "n/a";
    const percentageUnit = (secondsAvailable > 0) ? '%' : "";
    const spent = formatSeconds(secondsSpent)
    const avail = formatSeconds(secondsAvailable)
    const className = getPercentageClass(percentage);
    const smilie = (className === 'card-green') ? <span role="img" aria-label="Happy">&#x1F642;</span> : null
    return (
        <Segment className={className}>
            <Stats>
                <Stat major="Gebucht" minor="in KW" value={spent.time} unit={spent.unit} icon="book" />
                <Stat major="Verfügbar" minor="in KW" value={avail.time} unit={avail.unit} icon="calendar outline" />
                <Stat major="Auslastung" minor="in KW" value={percentage} unit={percentageUnit} icon="percent" /> 
                {smilie}
            </Stats>
        </Segment>
    )    
}
