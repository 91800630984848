import {
    sumBy,
    groupBy
} from 'lodash'

export function groupWorklogs(worklogs, groupKey, defaultColor = null, colorKey = null) {
    colorKey = colorKey ? colorKey : groupKey
    let byProject = groupBy(worklogs, `${groupKey}_id`)
    return Object.keys(byProject).map(key => {
        let values = byProject[key]
        let first = values[0]
        let data = {
            name: first[groupKey].name,
            seconds_spent: sumBy(values, 'seconds_spent'),
            color: first[colorKey].color,
        }
        if (!data.color && defaultColor) {
            data['color'] = defaultColor
        }
        return data;
    })
}
