import React, { useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react'
import { Error } from '../components/Error'
import { sortBy } from 'lodash'
import { CurrentUser } from '../data';
import { MainMenu } from '../components/MainMenu'

export function page(view, props, page) {

    const [loading, setLoading] = useState(true);
    const [me, setMe] = useState({
        'role': 'user'
    });


    const [error, setError] = useState();
    const params = props.match.params
    const paramValues = sortBy(Object.keys(params)).map(key => props.match.params[key])

    useEffect(() => {

        const a = CurrentUser.get().subscribe({
            next(user) {
                console.log(user)
                setError(null)
                setMe(user)
                setLoading(false)
            },
            error(err) {
                setLoading(false)
                setError(err)
                setMe(null)
            }
        })
        return () => a.unsubscribe()
    }, paramValues);

    if (error) {
        return (
            <Error error={error} />
        )
    }

    if (loading) {
        return <Loader active={true} />
    }

    return (
        <>
            <MainMenu user={me} />
            {page(me)}
        </>
    )
}
