import React from 'react';
import { TimeTable } from '../components/TimeTable'
import { TimePieChart } from '../components/TimePieChart'
import { TimeBarChart } from '../components/TimeBarChart'
import { UserStats } from '../components/UserStats'
import { WithTimeRange } from '../components/WithTimeRange'
import { WithPromise } from '../components/WithPromise'
import { DashboardElem } from '../components/DashboardElem'
import { Grid, Header } from 'semantic-ui-react'
import { getTimes } from '../api';
import { groupWorklogs } from '../functions/groupWorklogs';
import { sumBy } from 'lodash';

export function UserDashboard({ user }) {

    return (
        <WithTimeRange>{({ selected }) => {
            const start = selected.start
            const stop = selected.end
            const worklogPromise = getTimes({
                start: start.format('YYYY-MM-DD'),
                stop: stop.format('YYYY-MM-DD'),
                expand: 'project,customer,task,user,task_category',
                user_id: user.id,
            })
            return (
                <WithPromise promise={worklogPromise}>{(worklogs) => {
                    const data = {
                        start,
                        stop,
                        worklogs
                    }

                    let projectWorklogs = worklogs.filter(w => !w.internal_work);
                    let categoryWorklogs = groupWorklogs(worklogs, 'task_category');
                    let totalSeconds = sumBy(worklogs, w => w.seconds_spent);
                    let workWeekSeconds = user.week_hours * 60 * 60;
                    if (totalSeconds < workWeekSeconds) {
                        categoryWorklogs.push({
                            name: 'Nicht gebucht',
                            seconds_spent: workWeekSeconds - totalSeconds,
                            color: '#999999',
                        })
                    }

                    return (
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h2'>{user.name}</Header>
                                    <UserStats data={data} user={user} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row></Grid.Row>
                            <Grid.Row columns={3} >
                                <Grid.Column>
                                    <DashboardElem overline="Deine Produktivität" title="im Überblick" centered>
                                        <TimePieChart data={categoryWorklogs} />
                                    </DashboardElem>
                                </Grid.Column>
                                <Grid.Column>
                                    <DashboardElem overline="Deine Projektarbeit" title="nach Kunde" centered>
                                        <TimePieChart data={groupWorklogs(projectWorklogs, 'customer', '#ffff00')} />
                                    </DashboardElem>
                                </Grid.Column>
                                <Grid.Column>
                                    <DashboardElem overline="Deine Projektarbeit" title="nach Projekt" centered>
                                        <TimePieChart data={groupWorklogs(projectWorklogs, 'project', '#ffff00', 'customer')} />
                                    </DashboardElem>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <DashboardElem title="Stunden pro Tag">
                                        <TimeBarChart user={user} worklogs={worklogs} dates={[start, stop]} />
                                    </DashboardElem>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <DashboardElem title="Einträge">
                                        <TimeTable data={worklogs} />
                                    </DashboardElem>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )
                }}</WithPromise>
            )
        }}
        </WithTimeRange>
    )
}
