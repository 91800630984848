import React from 'react';
import { Icon } from 'semantic-ui-react'
import { classNames } from '../functions/classNames'
import { useSessionStorage } from '../hooks'
import { omit, range } from 'lodash'
import styles from './TreeTable.module.css';

function TreeTableHead({ config }) {
    return <thead>
        <tr>
            {config.columns.map(column => {
                const css = (column.highlight) ? styles.highlight : ''

                return (<th {...classNames([css])} key={column.key}>{column.renderHeader(column, config)}</th>)
            })}
        </tr>
    </thead>
}

function TreeTableBody({ config, data }) {
    return (
        <tbody>
            {data.map(row => <TreeTableRow key={row.id} config={config} row={row} level={0} />)}
        </tbody>
    )
}

function TreeTableRow({ config, row, level }) {
    const hasChildren = 'children' in row
    const rowId = row.id // TODO: This does not work with multiple levels
    const isOpen = config.isOpen(rowId)
    const children = (hasChildren && isOpen) ? row.children.map(row => <TreeTableRow key={row.id} config={config} row={row} level={level + 1} />) : null

    return (
        <>
            <tr>
                {config.columns.map((col, i) => {
                    const css = (col.highlight) ? styles.highlight : ''

                    return (
                        <td {...classNames([css])} key={col.key}>
                            {col.renderData(row, col, level, config)}
                        </td>
                    )
                })}
            </tr>
            {children}
        </>
    )
}

export function TreeTableExpand(props) {
    const { config, row, level } = props
    const hasChildren = 'children' in row && row.children.length > 0
    const rowId = row.id // TODO: This does not work with multiple levels
    const isOpen = config.isOpen(rowId)
    const handleClick = () => {
        if (hasChildren) {
            config.setOpen(rowId, !isOpen)
        }
    }
    return (
        <div className={styles.expander} onClick={handleClick}>
            <div>
                {range(level + 1).map(l => l === level && hasChildren).map((visible, i) => {
                    const visibleClass = (!visible) ? styles.hiddenIcon : ''
                    const rotatedClass = (isOpen) ? styles.openIcon : styles.closedIcon
                    return (
                        <Icon key={i} {...classNames(visibleClass, rotatedClass)} name='dropdown' />
                    )
                })}
            </div>
            <div>
                {props.children}
            </div>
        </div>
    )
}

export function TreeTable(props) {
    const { name } = props
    const [expansions, setExpansions] = useSessionStorage(`${name}.expansions`, {})
    const config = {
        setOpen: (id, open) => {
            if (!open) {
                setExpansions(omit(expansions, id))
                return
            }
            setExpansions({
                [id]: true,
                ...expansions
            })
        },
        isOpen: (id) => {
            return id in expansions
        },
        columns: props.columns.map((column, i) => {
            return {
                key: i,
                renderHeader: (column) => <>{column.title}</>,
                renderData: (row, column) => <>{row[column.key]}</>,
                ...column
            }
        })
    }
    return (
        <div>
            <table className={styles.table}>
                <TreeTableHead config={config} data={props.data} />
                <TreeTableBody config={config} data={props.data} />
            </table>
        </div>
    )
}
