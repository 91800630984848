import React from 'react';
import { getTimes, getAggregate } from '../api.js';
import { page } from '../functions/page'
import { ProjectInfo } from '../components/ProjectInfo'
import { TreeTable, TreeTableExpand } from '../components/TreeTable'
import { WithTimeRange } from '../components/WithTimeRange'
import { WithPromise } from '../components/WithPromise'
import { Header } from 'semantic-ui-react'
import { NavLink } from "react-router-dom";
import { createTreeFromWorklogs } from '../functions/createTreeFromWorklogs'
import { sumBy } from 'lodash'

function getData(start, stop) {
    return getTimes({
        start: start.format('YYYY-MM-DD'),
        stop: stop.format('YYYY-MM-DD'),
        expand: 'customer,task,project',
    })
}

function nameRenderer(row, column, level, config) {
    const heading = (level === 0) ? "h3" : "h4"
    const dashboardLink = (level === 1) ? (
        <NavLink to={`/projects/${row.id}`}>Zum Dashboard</NavLink>
    ) : null;
    return (
        <TreeTableExpand row={row} config={config} level={level}>
            <Header as={heading} >
                <Header.Content>
                    {row[column.key]}
                    <Header.Subheader>
                        <small>{dashboardLink}</small>
                    </Header.Subheader>
                </Header.Content>
            </Header>
        </TreeTableExpand>
    )
}

function dataRenderer(range, allWorklogs) {
    const startDate = parseInt(range.start.format("YYYYMMDD"))
    const endDate = parseInt(range.end.format("YYYYMMDD"))
    return (row, column, level, config) => {
        const rowsInRange = row.worklogs.filter(log => parseInt(log.day) >= startDate && parseInt(log.day) <= endDate)
        const totalSecondsEntry = sumBy(rowsInRange, log => parseInt(log.seconds_spent))
        const totalSeconds = sumBy(allWorklogs.filter(log => parseInt(log.day) >= startDate && parseInt(log.day) <= endDate), log => parseInt(log.seconds_spent))
        const percentage = (parseFloat(totalSecondsEntry) / parseFloat(totalSeconds)) * 100
        const stats = {
            seconds_spent: totalSecondsEntry,
            percentage_of_hours: percentage
        }
        return (
            <ProjectInfo id={row.id} stats={stats} />
        )
    }
}

export function Projects(props) {
    return page(`projects`, props, (me) =>
        <WithTimeRange>{({ selected, previous }) => {
            const start = previous.start
            const stop = selected.end
            return (
                <WithPromise promise={getData(start, stop)}>{(worklogs) => {
                    const tree = createTreeFromWorklogs(worklogs, 'customer', 'project')
                    return (
                        <TreeTable
                            columns={[
                                {
                                    key: "name",
                                    title: "",
                                    renderData: nameRenderer
                                },
                                {
                                    key: "2",
                                    title: previous.title,
                                    renderData: dataRenderer(previous, worklogs)
                                },
                                {
                                    key: "3",
                                    title: selected.title,
                                    highlight: true,
                                    renderData: dataRenderer(selected, worklogs)
                                }
                            ]}
                            data={tree}
                        />
                    )
                }}
                </WithPromise>
            )
        }}
        </WithTimeRange>

    )
}
