import React from 'react';
import moment from 'moment'
import 'moment/locale/de';
import { Seconds } from '../components/Seconds'
import { Table } from 'semantic-ui-react'

export function TimeTable(props) {
    let { data } = props
    let rows = data.map(row => {
        return (
            <Table.Row key={row.id} >
                <Table.Cell>{moment(row.started_at).format('ddd hh:mm')} Uhr</Table.Cell>
                <Table.Cell>
                    {row.project.name}<br />
                    <small>{row.customer.name}</small>
                    &nbsp;-&nbsp;
                    <small>{row.internal_work ? 'Interne Aufgaben' : 'Projektarbeit'}</small>
                </Table.Cell>
                <Table.Cell>
                    {row.task.name}<br />
                    <small>{row.system}</small>
                </Table.Cell>
                <Table.Cell textAlign='right'><Seconds seconds={row.seconds_spent} /></Table.Cell>
            </Table.Row>
        )
    })

    if (rows.length === 0) {
        rows = [
            <Table.Row key="none">
                <Table.Cell colSpan={4} textAlign="center">
                    <p>Keine Einträge in diesem Zeitraum</p>
                </Table.Cell>
            </Table.Row>
        ]
    }

    return (
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Datum</Table.HeaderCell>
                    <Table.HeaderCell>Projekt</Table.HeaderCell>
                    <Table.HeaderCell>Task</Table.HeaderCell>
                    <Table.HeaderCell>Zeit</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {rows}
            </Table.Body>

        </Table>
    )
}
