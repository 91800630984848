import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts'
import { Seconds } from './Seconds'
import styles from './TimePieChart.module.css';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

function CustomTooltip({ active, payload, label }) {
    if (active) {
        return (
            <div className={styles.tooltip}>
                <strong>{payload[0].payload.name}</strong>:<br />
                <Seconds seconds={payload[0].value} />
            </div>
        )
    }
    return null
}

function EmptyMessage() {
    return (
        <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p> Keine Zeiten vorhanden</p>
        </div>
    )
}

export function TimePieChart(props) {
    let { data } = props
    return (
        <div style={{ width: '100%', height: 300, display: 'relative' }}>
            <ResponsiveContainer>
                <PieChart  >
                    <Pie
                        data={data}
                        animationBegin={100}
                        animationDuration={750}
                        innerRadius="60%"
                        paddingAngle={2}
                        dataKey="seconds_spent" >
                        {data.map((entry, index) => {
                            let color = (entry.color) ? entry.color : COLORS[index % COLORS.length];
                            return <Cell key={index} label={entry.name} fill={color} />
                        })}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />

                </PieChart>
            </ResponsiveContainer>
            {(data.length === 0) ? <EmptyMessage /> : null}
        </div>
    );
}
