import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts'
import { sumBy, groupBy } from 'lodash'
import moment from 'moment'
import { getWeekdays } from "../functions/getWorkdays";


export function TimeBarChart({ user, worklogs, dates }) {
    let byDate = groupBy(worklogs, (log) => moment(log.started_at).format("dd DD.MM"))
    let weekdays = getWeekdays(dates[0], dates[1]).map(date => date.format('dd DD.MM'))
    let data = weekdays.map(key => {
        let values = byDate[key] || []
        return {
            name: key,
            Stunden: (sumBy(values, 'seconds_spent') / 60 / 60).toFixed(2),
        }
    })
    const hoursPerDay = user.week_hours / 5
    return (
        <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
                <BarChart data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, dataMax => Math.max(hoursPerDay + 1, dataMax + 1)]} />
                    <Tooltip />
                    <Bar dataKey="Stunden" fill="#82ca9d" />
                    <ReferenceLine y={hoursPerDay} stroke="gray" />


                    <Legend />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}
