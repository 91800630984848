import React from 'react';
import { page } from '../functions/page'
import { WithPromise } from '../components/WithPromise'
import { getProject, getProjectCharts } from '../api'
import { Header, Grid } from 'semantic-ui-react'
import moment from 'moment'
import { getWorkdays } from '../functions/getWorkdays'
import { groupBy, sumBy } from 'lodash'
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts'

function getWeeksForProject(project) {
    const begin = moment(project.begins_at).startOf('isoWeek')
    const end = moment(project.ends_at).startOf('isoWeek')
    let weeks = []
    while (begin <= end) {
        weeks.push(begin.clone())
        begin.add(7, 'days');
    }
    return weeks;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AABB88', '#EEEEE'];

function Percentage({ data }) {
    return (
        <div style={{ width: '100%', height: 200, display: 'relative' }}>
            <ResponsiveContainer>
                <PieChart  >
                    <Pie
                        startAngle={0} endAngle={360}
                        data={data}
                        animationBegin={100}
                        animationDuration={750}
                        outerRadius='90%'
                        innerRadius='70%'
                        dataKey="value" >
                        <Cell key="soll" label="Soll" fill="#880000" />
                        <Cell key="ist" label="Ist" fill="#008800" />
                    </Pie>

                </PieChart>
            </ResponsiveContainer>
        </div>
    )
}

export function Project(props) {
    const project = props.match.params.project
    const getData = Promise.all([getProject(project), getProjectCharts(project)])
    return page(`project/${project}`, props, _ => {
        return <WithPromise promise={getData}>{([project, logs]) => {
            if (!project.begins_at || !project.ends_at || !project.work_days_planned) {
                return (
                    <div>
                        <h1>{project.name}</h1>
                        <h2>Fehler</h2>
                        <p>Projektdaten sind noch unvollständig</p>
                    </div>
                )
            }

            const weeks = getWeeksForProject(project)
            const begin = moment(project.begins_at)
            const end = moment(project.ends_at)
            const now = moment()
            const departments = {}

            logs.forEach(log => departments[log.department_id] = log.department.name)
            const data = weeks.map(week => {
                const weekString = week.format('YYYYWW')
                const weekLogs = logs.filter(log => log.week === weekString)
                const byDepartment = groupBy(weekLogs, 'department_id')
                const data = {}
                Object.keys(byDepartment).forEach(id => {
                    data[departments[id]] = (sumBy(byDepartment[id], 'seconds_spent') / (24 * 60 * 60)).toFixed(2)
                })
                return {
                    name: week.format('WW YYYY'),
                    ...data
                }
            })
            const showReference = moment(project.begins_at) <= moment() <= moment(project.ends_at)
            const ptPerDay = project.work_days_planned / getWorkdays(begin, end).length
            const totalDays = (sumBy(logs, 'seconds_spent') / (8 * 60 * 60)).toFixed(2)
            const workUsed = (totalDays / parseFloat(project.work_days_planned) * 100).toFixed(2)
            var restzeit = <><strong>Restzeit</strong> (verbleibend bis KW {end.format("WW YYYY")}): {getWorkdays(now, end).length} Werktage<br /></>
            if (end < now) {
                restzeit = <><span style={{ color: 'red' }}><strong>Restzeit</strong> (vergangen seit KW {end.format("WW YYYY")}): {getWorkdays(end, now).length} Werktage<br /></span></>
            }
            return (
                <div>

                    <Header as='h2'>{project.name}</Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Header as='h3'>Zeit ({((getWorkdays(begin, now).length / getWorkdays(begin, end).length) * 100).toFixed(2)} %)</Header>
                                <Percentage data={[
                                    {
                                        "label": "Soll",
                                        "value": getWorkdays(begin, now).length,
                                    },
                                    {
                                        "label": "Ist",
                                        "value": getWorkdays(now, end).length,
                                    }
                                ]} />
                                <p>
                                    <strong>Geplante</strong> Zeit (brutto Gesamt): {getWorkdays(begin, end).length} Werktage<br />
                                    <strong>Laufzeit</strong> (bis Ende KW {moment().format("WW YYYY")}): {getWorkdays(begin, now).length} Werktage<br />
                                    {restzeit}
                                </p>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Header as='h3'>Aufwand ({workUsed} %)</Header>
                                <Percentage data={[
                                    {
                                        "label": "Soll",
                                        "value": parseFloat(workUsed),
                                    },
                                    {
                                        "label": "Ist",
                                        "value": Math.max(parseFloat(100.0 - workUsed), 0),
                                    }
                                ]} />
                                <p>
                                    <strong>Geplanter</strong> Aufwand: {(parseFloat(project.work_days_planned)).toFixed(2)} PT<br />
                                    <strong>Soll</strong>-Aufwand (bis Ende KW {now.format("WW YYYY")}): {(Math.min(getWorkdays(begin, now).length * ptPerDay, project.work_days_planned)).toFixed(2)} PT<br />
                                    <strong>Ist</strong>-Aufwand (bis Ende KW {now.format("WW YYYY")}): {totalDays} PT<br />
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Header as='h3'>Aufwand pro Woche (in PT)</Header>
                    <div style={{ clear: 'both', overflow: 'hidden', 'position': 'relative' }}>
                        <div style={{ float: 'left' }}>
                            {moment(project.begins_at).format('DD.MM.YYYY')}
                        </div>
                        <div style={{ float: 'right' }}>
                            {moment(project.ends_at).format('DD.MM.YYYY')}
                        </div>
                    </div>
                    <br />
                    <div style={{ width: '100%', height: 300 }}>


                        <ResponsiveContainer>
                            <BarChart
                                width={500}
                                height={300}
                                data={data}

                            >
                                {/*<CartesianGrid strokeDasharray="3 3" />*/}
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                {(showReference) ? <ReferenceLine x={moment().format('WW YYYY')} stroke="green" /> : null}
                                {Object.keys(departments).map((department, index) => <Bar key={department} dataKey={departments[department]} fill={COLORS[index % COLORS.length]} stackId="a" />)}


                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>)
        }}</WithPromise>

    }
    )
}
