import {
    useState
} from 'react';

function useStorage(storage, key, intialValue) {
    const [value, setItem] = useState(() => {
        const storedValue = storage.getItem(key)
        const value = (storedValue === null) ? intialValue : JSON.parse(storedValue)
        storage.setItem(key, JSON.stringify(value))
        return value;
    })

    const setValue = (value) => {
        setItem(value)
        storage.setItem(key, JSON.stringify(value))
    }

    return [value, setValue]
}

export function useLocalStorage(key, intialValue) {
    return useStorage(window.localStorage, key, intialValue)
}

export function useSessionStorage(key, intialValue) {
    return useStorage(window.sessionStorage, key, intialValue)
}
