import React from 'react';
import { getUser } from '../api.js';
import { page } from '../functions/page'
import { UserDashboard } from './UserDashboard'
import { WithPromise } from '../components/WithPromise'

export function User(props) {
    const user = props.match.params.user
    return page("dashboard", props, _ =>
        <WithPromise promise={getUser(user)}>{user =>
            <UserDashboard user={user} />
        }</WithPromise>
    )
}
