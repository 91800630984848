import React from 'react'
import { Stat } from './Stat'
import { ProjectSummary } from './ProjectSummary'
import { formatSeconds } from "../functions/formatSeconds"
import { Stats } from './Stats'
import { Segment, Popup } from 'semantic-ui-react'
import styles from './ProjectInfo.module.css';

function ProjectCard(props) {
    const { seconds_spent, percentage_of_hours } = props.stats
    const spent = formatSeconds(seconds_spent)
    return (
        <div className={styles.card}>
            <Segment>
                <Stats>
                    <Stat major="Gebucht" minor="in KW" value={spent.time} unit={spent.unit} icon="book" />
                    <Stat major="Anteil" minor="Am Gesamten" value={percentage_of_hours.toFixed(2)} unit="%" icon="sigma" />
                </Stats>
            </Segment>
        </div>
    )
}

export function ProjectInfo(props) {
    return (
        <ProjectCard {...props} />
    )
}  
