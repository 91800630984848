import React from 'react';
import { Message, Icon, Button } from 'semantic-ui-react'

export function Error(props) {
    let {
        error,
        header = 'Es ist ein Fehler aufgetreten',
        message = error.message
    } = props
    const isUnauthenticated = error.response && error.response.status === 401;
    if (isUnauthenticated) {
        header = 'Sie wurden abgemeldet'
        message = 'Bitte melden Sie sich erneut an'
    }
    return (
        <Message negative icon>
            <Icon name='warning circle' />
            <Message.Content>
                <Message.Header>{header}</Message.Header>
                <p>{message}</p>
                {(isUnauthenticated) ? <Button as="a" href="/api/v1/login/google/redirect" size='small'>Anmelden</Button> : null}
            </Message.Content>
        </Message>
    )
}
