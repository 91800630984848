import axios from 'axios';
import { groupBy, slice, sumBy, mapValues } from 'lodash'
import moment from 'moment';

const client = axios.create({
    baseURL: '/api/v1',
    headers: {}
});

function getData(response) {
    if (response && response.data) {
        return response.data
    }
    return response
}

export function getUser(id) {
    return client.get('/user/' + id).then(getData)
}

export function getProject(id) {
    return client.get('/projects/' + id).then(getData)
}

export function getDepartments() {
    return client.get('/departments/').then(getData)
}

export function getProjectCharts(projectId) {
    return getTimes({
        'project_id': projectId,
        'expand': 'department'
    }).then(worklogs => {
        const worklogsWithWeek = worklogs.map(worklog => {
            // Add week field to worklog
            return {
                ...worklog,
                week: moment(worklog.date).format("YYYYWW")
            }
        })
        const worklogsByDeptAndWeek = Object.values(groupBy(worklogsWithWeek, worklog => `${worklog.department_id}+${worklog.week}`))
        const aggregatedWorklogsByDeptAndWeek = worklogsByDeptAndWeek.map(group => {
            return {
                ...group[0],
                seconds_spent: sumBy(group, log => log.seconds_spent)
            }
        })
        return aggregatedWorklogsByDeptAndWeek;
    })
}

export function getTimes(params) {
    return client.get('/times/', {
        'params': params
    }).then(getData)
}

export function getAggregate(params) {
    return client.get('/times/aggregate', {
        'params': params
    }).then(getData)
}