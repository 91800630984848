import React from 'react';
import { Menu } from 'semantic-ui-react'
import { NavLink } from "react-router-dom";

function AdminMenu() {
    return (
        <>
            <Menu.Item
                as={NavLink}
                name='Resourcen'
                to={"/resources/"}
            />
        </>
    )
}
export function MainMenu(props) {
    return (
        <Menu >
            <Menu.Item
                as={NavLink}
                name='Meine Zeiten'
                to={"/dashboard/"}
            />
            <Menu.Item
                as={NavLink}
                name='Projekte'
                to={"/projects/"}
            />
            {(props.user.role === 'admin') ? <AdminMenu /> : null}
            <Menu.Item
                position="right"
                onClick={(x) => window.location.href = '/api/v1/logout'}
                name='Logout'
            />
        </Menu>
    )
}

