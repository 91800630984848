import React from 'react';
import moment from 'moment'
import { Icon, Button, Header, Divider } from 'semantic-ui-react'
import styles from './WithTimeRange.module.css';
import { useSessionStorage } from '../hooks'
import { classNames } from '../functions/classNames'

const RESOLUTIONS = [
    {
        id: 'isoWeek',
        title: 'Woche',
        'prefix': 'KW',
        nowLabel: 'Diese Woche',
        format: 'W YYYY',

    },
    {
        id: 'month',
        title: 'Monat',
        'prefix': '',
        nowLabel: 'Dieser Monat',
        format: 'MMM YYYY',

    },
    {
        id: 'quarter',
        title: 'Quartal',
        'prefix': 'Q',
        nowLabel: 'Dieses Quartal',
        format: 'Q YYYY',
    },
    {
        id: 'year',
        title: 'Jahr',
        'prefix': '',
        nowLabel: 'Dieses Jahr',
        format: 'YYYY',
    },
];

export function WithTimeRange(props) {
    const defaultRange = RESOLUTIONS[1];
    const [timeRange, setTimeRange] = useSessionStorage('date', {
        "selectedDateStr": moment().startOf(defaultRange.id).format(),
        "selectedRangeId": defaultRange.id
    })

    const { selectedDateStr, selectedRangeId } = timeRange;
    const selectedRange = RESOLUTIONS.find(r => r.id === selectedRangeId)
    const selectedDate = moment(selectedDateStr).startOf(selectedRange.id);

    const rangeStartDate = selectedDate.clone();
    const rangeEndDate = selectedDate.clone().endOf(selectedRange.id)


    function DateLink(props) {
        const { date } = props
        return <div onClick={() => {
            setTimeRange({
                "selectedDateStr": date.format(),
                "selectedRangeId": selectedRangeId
            })
        }} >{props.children}</div>
    }

    function RangeLink(props) {
        const { range } = props
        const classes = [styles.rangeLink]
        if (range.id === selectedRangeId) {
            classes.push(styles.active)
        }
        return <span {...classNames(classes)} onClick={() => {
            setTimeRange({
                "selectedDateStr": selectedDate.clone().startOf(range.id),
                "selectedRangeId": range.id
            })
        }} >{range.title}</span>
    }

    const ranges = {
        selected: {
            title: `${selectedRange.prefix}${selectedDate.format(selectedRange.format)}`,
            start: rangeStartDate,
            end: rangeEndDate
        },
        previous: {
            title: `${selectedRange.prefix}${selectedDate.clone().subtract(1, selectedRange.id).format(selectedRange.format)}`,
            start: selectedDate.clone().subtract(1, selectedRange.id),
            end: rangeStartDate.clone().subtract(1, 'seconds')
        },
        next: {
            title: `${selectedRange.prefix}${selectedDate.clone().add(1, selectedRange.id).format(selectedRange.format)}`,
            start: selectedDate.clone().add(1, selectedRange.id),
            end: selectedDate.clone().add(2, selectedRange.id)
        },
        resolution: selectedRange
    }

    return (
        <>
            <div>
                <div>
                    <DateLink date={moment()}>
                        <Button size='mini' floated='left' icon labelPosition='left' className={styles.todayBtn}>
                            <Icon name='calendar outline' />
                            {selectedRange.nowLabel}
                        </Button>
                    </DateLink>
                    <DateLink date={ranges.previous.start}>
                        <Button size='mini' floated='left' icon labelPosition='left'>
                            Zurück
                                    <Icon name='left arrow' />
                        </Button>
                    </DateLink>
                    <DateLink date={ranges.next.start}>
                        <Button size='mini' floated='left' icon labelPosition='right'>
                            Weiter
                                    <Icon name='right arrow' />
                        </Button>
                    </DateLink>
                    <Header
                        title={`${ranges.selected.start.format('DD.MM.YYYY')} bis ${ranges.selected.end.format('DD.MM.YYYY')}`} textAlign='right' as="h2">
                        {ranges.selected.title}
                    </Header>
                </div>
                <div>
                    <small><em>Auflösung:</em> {RESOLUTIONS.map(r => <RangeLink range={r} key={r.id} />)}</small>
                </div>

            </div>
            <Divider />
            {props.children(ranges)}
        </>
    )
}
